import { createAsyncThunk } from '@reduxjs/toolkit'

import { setupBookings } from './setupBookings'
import { setupContents } from './setupContent'
import { setupCustomerDetails } from './setupCustomerDetails'
import { setupLocations } from './setupLocations'
import { setupOrgUnits } from './setupOrgUnits'
import { setupPassengers } from './setupPassengers'
import { setupVehicles } from './setupVehicles'

export type SetupPayload = {
  //
}

export const setupApp = createAsyncThunk('app/setup', async ({ }: SetupPayload, { dispatch }) => {
  await Promise.allSettled([
    // Get customer information
    dispatch(setupCustomerDetails()),

    // Get locations
    dispatch(setupLocations()),

    // Get available vehicles
    dispatch(setupVehicles({})),

    // Get user bookings
    dispatch(setupBookings()),

    // Get all passengers
    dispatch(setupPassengers()),

    // Get onboarding messages
    dispatch(setupContents()),

    // Setup customer org units
    dispatch(setupOrgUnits()),
  ])
})