import { CarshareApiService } from 'src/apis/CarshareApiService'
import { OrgUnit } from 'src/types/OrgUnit'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { replaceAllOrgUnits } from '../reducers/orgUnits'

export const setupOrgUnits = createAsyncThunk('orgUnits/setup', async (_, { dispatch }) => {
  // Retrieve Customer Org Units
  const orgUnits = await CarshareApiService.post<OrgUnit>('getCustomerOrgUnits')

  if (orgUnits.results) {
    dispatch(replaceAllOrgUnits(orgUnits.results))
  }
})