import { OrgUnit } from 'src/types/OrgUnit'

import { createEntityAdapter } from '@reduxjs/toolkit'

import { RootState } from '../store'

export const orgUnitsAdapter = createEntityAdapter({
  selectId: (orgUnit: OrgUnit) => orgUnit.ORG_UNIT_ID,
})

export const orgUnitsSelectors = orgUnitsAdapter.getSelectors<RootState>(
  (state) => state.orgUnits,
)
