
import { OrgUnit } from 'src/types/OrgUnit'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { orgUnitsAdapter } from '../adapters/orgUnits'

export const initialState = orgUnitsAdapter.getInitialState()

export const { reducer, actions } = createSlice({
  name: 'orgUnits',
  initialState,
  reducers: {
    addMultipleOrgUnits: (state, action: PayloadAction<OrgUnit[]>) => {
      orgUnitsAdapter.upsertMany(state, action.payload)
    },
    replaceAllOrgUnits: (state, action: PayloadAction<OrgUnit[]>) => {
      orgUnitsAdapter.setAll(state, action.payload)
    },
  },
})

export const { addMultipleOrgUnits, replaceAllOrgUnits } = actions