/* eslint-disable @typescript-eslint/no-explicit-any */

import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useMemo, useRef, useState } from 'react'
import { AutoComplete } from 'src/components/Autocomplete/Autocomplete'
import { Button } from 'src/components/Button/Button'
import { DateTimePicker } from 'src/components/DateTimePicker/DateTimePicker'
import { Dropdown } from 'src/components/Dropdown/Dropdown'
import { DropdownItem } from 'src/components/Dropdown/DropdownItem'
import { Icon } from 'src/components/Icon/Icon'
import { IconButton } from 'src/components/IconButton/IconButton'
import { MultiselectDropdown } from 'src/components/MultiselectDropdown/MultiselectDropdown'
import { MultiselectDropdownItem } from 'src/components/MultiselectDropdown/MultiselectDropdownItem'
import { useDrawerSwipe } from 'src/hooks/useDrawerSwipe'
import { locationsSelectors } from 'src/store/adapters/locations'
import { vehiclesSelectors } from 'src/store/adapters/vehicles'
import { initialFilterState, setFilterOptions } from 'src/store/reducers/settings'
import { useAppDispatch, useAppSelector } from 'src/store/store'
import { pxToRem } from 'src/styles/themes'

import { faAngleDown } from '@fortawesome/pro-light-svg-icons'
import { faChevronLeft, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material'

import {
  StyledAccordion, StyledAccordionDetails, StyledAccordionSummary, StyledAngleIcon, StyledFilterButton, StyledFilterHeader, StyledFilterSidebar,
  StyledFilterTypography, StyledGroupTypography, StyledOptionTypography, StyledRangeSlider, StyledToolbar,
} from './StyledFilterSidebar'

export type FilterSidebarProps = {
  hide: boolean
  onHideFilter: () => void
  onClearFilter: () => void
  availableVehicles: number
}

type Option = {
  label: string
  id: string | null
}

export const FilterSidebar: React.FC<FilterSidebarProps> = ({ hide, availableVehicles, onHideFilter, onClearFilter }) => {
  const dispatch = useAppDispatch()
  const filterOptions = useAppSelector((state) => state.settings.filterOptions) ?? initialFilterState
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const vehicles = useAppSelector((state) => vehiclesSelectors.selectAll(state)).filter((vehicle) => vehicle.vehicle_availability === 'Available')
  const locations = useAppSelector(state => locationsSelectors.selectAll(state))
  const isWaipa = useAppSelector(state => state.user.ui_type === 'WAIPA')
  const sidebarRef = useRef<HTMLDivElement>(null)

  const [pickupTimeErrorMessage, setPickupTimeErrorMessage] = useState<string | null>(null)
  const [dropoffTimeErrorMessage, setDropoffTimeErrorMessage] = useState<string | null>(null)

  const focusSidebar = () => {
    if (isMobile && sidebarRef && sidebarRef.current) {
      sidebarRef.current?.focus()
    }
  }

  //Swipe to close
  const { style, ...swipeHandlers } = useDrawerSwipe({
    onSwipedRight: () => {
      onHideFilter()
    },
  })

  useEffect(() => {
    if (isMobile) {
      if (!hide) {
        focusSidebar()
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    }
  }, [hide, isMobile])

  const makeOptions: Option[] = useMemo(() => {
    const seen = new Set()

    const options: Option[] = vehicles
      .map(vehicle => ({
        label: vehicle.make_name + ' ' + vehicle.model_name,
        id: vehicle.make_name + ' ' + vehicle.model_name,
      }))
      .filter(option => {
        if (option.label.trim() === '') return false

        const duplicate = seen.has(option.id)

        seen.add(option.id)

        return !duplicate
      })

    options.unshift({ label: 'All', id: null })

    return options
  }, [vehicles])

  const fuelOptions: Option[] = useMemo(() => {
    const seen = new Set()

    return vehicles
      .map(vehicle => ({
        label: vehicle.vehicle_fuel.charAt(0).toUpperCase() + vehicle.vehicle_fuel.slice(1).toLowerCase(),
        id: vehicle.vehicle_fuel.charAt(0).toUpperCase() + vehicle.vehicle_fuel.slice(1).toLowerCase(),
      }))
      .filter(option => {
        if (option.label.trim() === '') return false

        const duplicate = seen.has(option.id)

        seen.add(option.id)

        return !duplicate
      })
  }, [vehicles])

  const transmissionOptions: Option[] = useMemo(() => {
    const seen = new Set()

    return vehicles
      .map(vehicle => ({
        label: vehicle.vehicle_transmission.charAt(0).toUpperCase() + vehicle.vehicle_transmission.slice(1).toLowerCase(),
        id: vehicle.vehicle_transmission.charAt(0).toUpperCase() + vehicle.vehicle_transmission.slice(1).toLowerCase(),
      }))
      .filter(option => {
        if (option.label.trim() === '') return false

        const duplicate = seen.has(option.id)

        seen.add(option.id)

        return !duplicate
      })
  }, [vehicles])

  const attributesOptions: Option[] = useMemo(() => {
    const attributesSet = new Set<string>()

    vehicles?.forEach(vehicle => {
      vehicle.vehicle_attribute_names?.split(',').forEach(attribute => {
        if (attribute.charAt(0).toUpperCase() + attribute.slice(1).toLowerCase()) {
          attributesSet.add(attribute.charAt(0).toUpperCase() + attribute.slice(1).toLowerCase())
        }
      })
    })

    return Array.from(attributesSet).map(opt => ({
      label: opt,
      id: opt,
    }))
  }, [vehicles])

  const pickupLocationsOptions: Option[] = useMemo(() => {
    const seen = new Set()

    const toptions = vehicles
      .map(vehicle => ({
        label: locations.find((loc) => loc.LOCATION_ID === vehicle.LOCATION_ID)?.location_name || '',
        id: vehicle.LOCATION_ID,
      }))
      .filter(option => {
        if (option.label.trim() === '') return false

        const duplicate = seen.has(option.id)

        seen.add(option.id)

        return !duplicate
      })

    return toptions
  }, [locations, vehicles])

  return (
    <StyledFilterSidebar
      {...swipeHandlers}
      ref={sidebarRef}
      $hide={hide}
      style={isMobile && !hide ? style : {}}
    >
      <StyledFilterHeader container alignItems={'center'} justifyContent={'space-between'}>
        <Grid item>
          <IconButton sx={{ marginLeft: pxToRem(-10) }} onClick={onHideFilter}>
            <Icon icon={faChevronLeft} />
          </IconButton>
        </Grid>
        <Grid item>
          <StyledFilterTypography variant="body1">Filter</StyledFilterTypography>
        </Grid>
        <Grid item>
          <IconButton sx={{ marginRight: pxToRem(-12) }} onClick={onHideFilter}>
            <Icon icon={faTimes} />
          </IconButton>
        </Grid>
      </StyledFilterHeader>

      <StyledToolbar container alignItems={'center'} justifyContent={'space-between'}>
        <Grid item>
          <Typography
            variant="body1"
            sx={{
              textTransform: 'none',
              fontSize: pxToRem(14),
              fontWeight: 500,
            }}
          >
            {availableVehicles} vehicles available
          </Typography>
        </Grid>
        <Grid item>
          <Button
            text
            round
            onClick={onClearFilter}
            sx={{
              marginRight: pxToRem(-14),
              textTransform: 'none',
              fontSize: pxToRem(14),
              fontWeight: 500,
            }}
          >
            Clear Filter
          </Button>
        </Grid>
      </StyledToolbar>

      <StyledAccordion defaultExpanded={true}>
        <StyledAccordionSummary
          expandIcon={<StyledAngleIcon icon={faAngleDown} />}
          aria-controls="filter-availability"
          id="filter-availability"
        >
          <StyledGroupTypography variant="body1">Availability</StyledGroupTypography>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <StyledOptionTypography variant="body1">Pickup Date & Time</StyledOptionTypography>
          <DateTimePicker
            aria-label="Pickup Date and Time"
            disablePast
            minutesStep={15}
            defaultValue={null}
            format="DD/MM/YYYY hh:mm A"
            value={dayjs.utc(filterOptions.fromDate).set('hour', filterOptions.pickupHour).set('minute', filterOptions.pickupMinute)}
            onChange={(date) => {
              if (!date) return

              const dateTimeUtc = dayjs.utc(date as Dayjs)

              if (!dateTimeUtc.isValid()) return

              dispatch(setFilterOptions({
                fromDate: dateTimeUtc.format('YYYY-MM-DD'),
                pickupHour: dateTimeUtc.hour(),
                pickupMinute: dateTimeUtc.minute(),
              }))
            }}
            onAccept={(date) => {
              if (!date) return

              const dateTimeUtc = dayjs.utc(date as Dayjs)

              dispatch(setFilterOptions({
                fromDate: dateTimeUtc.format('YYYY-MM-DD'),
                pickupHour: dateTimeUtc.hour(),
                pickupMinute: dateTimeUtc.minute(),
              }))
            }}
            onError={(error) => {
              if (!error) {
                setPickupTimeErrorMessage(null)
              } else if (error === 'invalidDate') {
                setPickupTimeErrorMessage('Invalid date & time')
              } else if (error === 'minutesStep') {
                setPickupTimeErrorMessage('Minutes must be in 15 minute intervals (00, 15, 30, 45).')
              }
            }}
            error={pickupTimeErrorMessage}
          />

          <StyledOptionTypography variant="body1" sx={{ marginTop: pxToRem(12) }}>Dropoff Date & Time</StyledOptionTypography>
          <DateTimePicker
            aria-label="Dropoff Date and Time"
            disablePast
            minutesStep={15}
            minDate={dayjs.utc(filterOptions.fromDate).set('hour', filterOptions.pickupHour).set('minute', filterOptions.pickupMinute)}
            defaultValue={null}
            format="DD/MM/YYYY hh:mm A"
            value={dayjs.utc(filterOptions.toDate).set('hour', filterOptions.dropoffHour).set('minute', filterOptions.dropoffMinute)}
            onChange={(date) => {
              if (!date || !(date as Dayjs)?.isValid()) return

              const dateTimeUtc = dayjs.utc(date as Dayjs)

              dispatch(setFilterOptions({
                toDate: dateTimeUtc.format('YYYY-MM-DD'),
                dropoffHour: dateTimeUtc.hour(),
                dropoffMinute: dateTimeUtc.minute(),
              }))
            }}
            onAccept={(date) => {
              if (!date) return

              const dateTimeUtc = dayjs.utc(date as Dayjs)

              dispatch(setFilterOptions({
                toDate: dateTimeUtc.format('YYYY-MM-DD'),
                dropoffHour: dateTimeUtc.hour(),
                dropoffMinute: dateTimeUtc.minute(),
              }))
            }}
            onError={(error) => {
              if (!error) {
                setDropoffTimeErrorMessage(null)
              } else if (error === 'invalidDate') {
                setDropoffTimeErrorMessage('Invalid date & time')
              } else if (error === 'minutesStep') {
                setDropoffTimeErrorMessage('Minutes must be in 15 minute intervals (00, 15, 30, 45).')
              }
            }}
            error={dropoffTimeErrorMessage}
          />

          <StyledOptionTypography variant="body1" sx={{ marginTop: pxToRem(12) }}>Pickup Location</StyledOptionTypography>
          <Dropdown
            aria-label="Pickup Location"
            sx={{ width: '100%' }}
            value={pickupLocationsOptions.find(option => option.id === filterOptions.pickupLocation)?.label || 'All'}
            onSelectItem={(value) => {
              dispatch(setFilterOptions({
                pickupLocation: value,
              }))
              focusSidebar()
            }}
          >
            <DropdownItem key={'All'} data-value={null}>All</DropdownItem>
            {pickupLocationsOptions.map(option => {
              return (
                <DropdownItem key={option.id} data-value={option.id}>{option.label}</DropdownItem>
              )
            })}
          </Dropdown>

        </StyledAccordionDetails>
      </StyledAccordion>

      <StyledAccordion defaultExpanded={true}>
        <StyledAccordionSummary
          expandIcon={<StyledAngleIcon icon={faAngleDown} />}
          aria-controls="filter-configurations"
          id="filter-configurations"
        >
          <StyledGroupTypography variant="body1">Configurations</StyledGroupTypography>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          {!isWaipa &&
            <>
              <StyledOptionTypography variant="body1">Minimum Range</StyledOptionTypography>
              <StyledRangeSlider
                aria-label="Range"
                value={filterOptions.range}
                min={150}
                max={1000}
                onChange={(_, newValue) => {
                  if (!newValue) return

                  dispatch(setFilterOptions({
                    range: newValue as number,
                  }))
                  focusSidebar()
                }}
                marks={[
                  {
                    value: 150,
                    label: '150km+',
                  },
                  {
                    value: 1000,
                    label: '1000km+',
                  },
                ]}
              />
            </>
          }

          <StyledOptionTypography variant="body1" sx={{ marginTop: pxToRem(16) }}>Vehicle Brand</StyledOptionTypography>
          <AutoComplete
            id="make-selector"
            options={makeOptions}
            value={makeOptions.find(option => option.id === filterOptions.make)}
            isOptionEqualToValue={(option: any, value: any) => {
              if (!value || !option) return false
              
              return option.id === value.id
            }}
            onChange={(_, value: any) => {
              if (!value) return

              dispatch(setFilterOptions({
                make: value.id,
              }))
            }}
          />

          <Grid container columnSpacing={2}>
            <Grid item xs={6} sx={{ width: '100%' }} >
              <StyledOptionTypography variant="body1" sx={{ marginTop: pxToRem(12) }}>Minimum Seats</StyledOptionTypography>
              <Dropdown
                sx={{ width: '100%' }}
                value={`${filterOptions.seats}+`}
                onSelectItem={(value) => {
                  dispatch(setFilterOptions({
                    seats: value,
                  }))
                  focusSidebar()
                }}
              >
                {[1, 2, 3, 4].map(option => {
                  return (
                    <DropdownItem key={option} data-value={option}>{option}+</DropdownItem>
                  )
                })}
              </Dropdown>
            </Grid>
            {!isWaipa && <Grid item xs={6} sx={{ width: '100%' }}>
              <StyledOptionTypography variant="body1" sx={{ marginTop: pxToRem(12) }}>Minimum Luggage</StyledOptionTypography>
              <Dropdown
                sx={{ width: '100%' }}
                value={`${filterOptions.luggages}+`}
                onSelectItem={(value) => {
                  dispatch(setFilterOptions({
                    luggages: value,
                  }))
                  focusSidebar()
                }}
              >
                {[1, 2, 3, 4].map(option => {
                  return (
                    <DropdownItem key={option} data-value={option}>{option}+</DropdownItem>
                  )
                })}
              </Dropdown>
            </Grid>}
            <Grid item xs={6} sx={{ width: '100%' }}>
              <StyledOptionTypography variant="body1" sx={{ marginTop: pxToRem(12) }}>Fuel Type</StyledOptionTypography>
              <Dropdown
                sx={{ width: '100%' }}
                value={fuelOptions.find(option => option.id === filterOptions.fuelType)?.label || 'All'}
                onSelectItem={(value) => {
                  dispatch(setFilterOptions({
                    fuelType: value,
                  }))
                  focusSidebar()
                }}
              >
                <DropdownItem key={'All'} data-value={null}>All</DropdownItem>
                {fuelOptions.map(option => {
                  return (
                    <DropdownItem key={option.id} data-value={option.id}>{option.label}</DropdownItem>
                  )
                })}
              </Dropdown>
            </Grid>
            <Grid item xs={6} sx={{ width: '100%' }}>
              <StyledOptionTypography variant="body1" sx={{ marginTop: pxToRem(12) }}>Transmission</StyledOptionTypography>
              <Dropdown
                sx={{ width: '100%' }}
                value={transmissionOptions.find(option => option.id === filterOptions.transmission)?.label || 'All'}
                onSelectItem={(value) => {
                  dispatch(setFilterOptions({
                    transmission: value,
                  }))
                  focusSidebar()
                }}
              >
                <DropdownItem key={'All'} data-value={null}>All</DropdownItem>
                {transmissionOptions.map(option => {
                  return (
                    <DropdownItem key={option.id} data-value={option.id}>{option.label}</DropdownItem>
                  )
                })}
              </Dropdown>
            </Grid>
          </Grid>
        </StyledAccordionDetails>
      </StyledAccordion>

      <StyledAccordion defaultExpanded={true}>
        <StyledAccordionSummary
          expandIcon={<StyledAngleIcon icon={faAngleDown} />}
          aria-controls="filter-vehicle-features-attributes"
          id="filter-vehicle-features-attributes"
        >
          <StyledGroupTypography variant="body1">Attributes</StyledGroupTypography>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <StyledOptionTypography variant="body1" sx={{ marginTop: pxToRem(12) }}>Attributes</StyledOptionTypography>
          <MultiselectDropdown
            values={filterOptions?.attributes?.map(item => ({
              name: item,
              label: item,
            })) ?? []}
            onSelectItem={(val) => {
              const index = filterOptions.attributes.indexOf(val.name)

              if (index > -1) {
                const newSelectedItems = [...filterOptions.attributes]

                newSelectedItems.splice(index, 1)
                dispatch(setFilterOptions({
                  attributes: newSelectedItems,
                }))
              } else {
                dispatch(setFilterOptions({
                  attributes: [...filterOptions.attributes, val.name],
                }))
              }
            }}
          >
            {attributesOptions.map(option => {
              return (
                <MultiselectDropdownItem
                  key={option.id}
                  value={{
                    name: option.label,
                    label: option.label,
                  }}
                >
                  {option.label}
                </MultiselectDropdownItem>
              )
            })}
          </MultiselectDropdown>
        </StyledAccordionDetails>
      </StyledAccordion>
      <StyledFilterButton
        primary
        fullWidth
        onClick={onHideFilter}
      >
        Apply Filter
      </StyledFilterButton>
    </StyledFilterSidebar>
  )
}
