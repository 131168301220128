import { combineReducers, createAction } from '@reduxjs/toolkit'

import { initialState as bookingsInitialState, reducer as bookings } from './bookings'
import { initialState as contentsInitialState, reducer as contents } from './contents'
import { initialState as customersInitialState, reducer as customers } from './customers'
import { initialState as imagesInitialState, reducer as images } from './images'
import { initialState as locationsInitialState, reducer as locations } from './locations'
import { initialState as membersInitialState, reducer as members } from './members'
import { initialState as orgUnitsInitialState, reducer as orgUnits } from './orgUnits'
import { initialState as passengersInitialState, reducer as passengers } from './passengers'
import { initialState as settingsInitialState, reducer as settings } from './settings'
import { initialState as userInitialState, reducer as user } from './user'
import { initialState as vehiclesInitialState, reducer as vehicles } from './vehicles'

export const appReducer = combineReducers({
  user,
  settings,
  bookings,
  vehicles,
  customers,
  locations,
  members,
  images,
  passengers,
  contents,
  orgUnits,
})

export const RESET_STATE = 'RESET_STATE'

export const rootReducer: typeof appReducer = (state, action) => {
  if (action.type === RESET_STATE) {
    return appReducer({
      user: userInitialState,
      settings: settingsInitialState,
      bookings: bookingsInitialState,
      vehicles: vehiclesInitialState,
      customers: customersInitialState,
      locations: locationsInitialState,
      members: membersInitialState,
      images: imagesInitialState,
      passengers: passengersInitialState,
      contents: contentsInitialState,
      orgUnits: orgUnitsInitialState,
    }, action)
  }

  return appReducer(state, action)
}

export const resetState = createAction(RESET_STATE)

export default rootReducer
