import dayjs from 'dayjs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { AnimatedPage } from 'src/components/AnimatedPage/AnimatedPage'
import { MobileSlider } from 'src/components/MobileSlider/MobileSlider'
import { Modal } from 'src/components/Modal/Modal'
import { VehicleSearchContext } from 'src/components/OperationsCalander/VehicleSearchContext'
import { VehicleCardPlaceholder } from 'src/components/VehicleCard/VehicleCardPlaceholder'
import { BookingForm } from 'src/fragments/BookingForm/BookingForm'
import { CalendarViewFragment } from 'src/fragments/Explore/CalendarViewFragment'
import { FilterSidebar } from 'src/fragments/Explore/FilterSidebar'
import { Header } from 'src/fragments/Explore/Header'
import { VehicleCardFragment } from 'src/fragments/Explore/VehicleCardFragment'
import { countFilterOptionsChanges } from 'src/libs/countFilterOptionsChanges'
import { vehiclesSelectors } from 'src/store/adapters/vehicles'
import { initialFilterState, resetFilterOptions, setFilterOptions } from 'src/store/reducers/settings'
import { useAppDispatch, useAppSelector } from 'src/store/store'
import { pxToRem } from 'src/styles/themes'
import { ExistingBooking } from 'src/types/ExistingBooking'
import { Vehicle } from 'src/types/Vehicle'

import { Box, Container, debounce, Grid, styled, Typography, useMediaQuery, useTheme } from '@mui/material'

export const Explore = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const dispatch = useAppDispatch()
  const vehicles = useAppSelector((state) => vehiclesSelectors.selectAll(state))
  const filterOptions = useAppSelector((state) => state.settings.filterOptions) || initialFilterState

  const [isFilterHide, setFilterHide] = useState(isMobile ? true : false)
  const [searchValue, setSearchValue] = useState('')
  const [allVehicles, setAllVehicles] = useState<Vehicle[]>([])
  const [availableVehicles, setAvailableVehicles] = useState<Vehicle[]>(vehicles)
  const [existingBookings, setExistingBookings] = useState<ExistingBooking[] | null>([])
  const [activeVehicleId, setActiveVehicleId] = useState<Vehicle['VEHICLE_ID'] | null>(null)
  const [initialLoad, setInitialLoad] = useState(true)
  const [isCalendarView, setCalendarView] = useState(false)
  const [currentDate, setCurrentDate] = useState(dayjs())

  // Filtered Vehicles by From and To Dates
  const debouncedVehicleSearch = debounce(() => {
    const fromDateObj = dayjs(filterOptions?.fromDate)
    const toDateObj = dayjs(filterOptions?.toDate)

    if (filterOptions.fromDate && filterOptions.toDate && (fromDateObj.isSame(toDateObj) || toDateObj.isAfter(fromDateObj))) {
      const fromDate = filterOptions.fromDate
      const toDate = filterOptions.toDate

      CarshareApiService.post<Vehicle>('getVehiclesSearch', {
        date_from: fromDate,
        date_to: toDate,
        time_pickup: `${filterOptions.pickupHour.toString().padStart(2, '0')}:${filterOptions.pickupMinute.toString().padStart(2, '0')}:00`,
        time_dropoff: `${filterOptions.dropoffHour.toString().padStart(2, '0')}:${filterOptions.dropoffMinute.toString().padStart(2, '0')}:00`,
      }).then((res) => {
        if (res.status === 'OK' && res.results !== null) {
          setAvailableVehicles(res.results)
        }

        setInitialLoad(false)
      })
    }
  }, 150)

  useEffect(() => {
    if (!filterOptions.fromDate || !filterOptions.toDate) return

    debouncedVehicleSearch()

    return () => {
      debouncedVehicleSearch.clear()
    }
  }, [filterOptions.fromDate, filterOptions.toDate, filterOptions.pickupHour, filterOptions.pickupMinute, filterOptions.dropoffHour, filterOptions.dropoffMinute])

  // Fetch all vehicles on initial load
  useEffect(() => {
    CarshareApiService.post<Vehicle>('getVehicles', {}).then((res) => {
      if (res.status === 'OK' && res.results !== null) {
        setAllVehicles(res.results)
      }
    })
  }, [])

  // Refresh existing bookings when current date changes
  const refreshExistingBookings = useCallback(() => {
    const fromDate = currentDate.format('YYYY-MM-DD')
    const toDate = currentDate.format('YYYY-MM-DD')

    CarshareApiService.post<ExistingBooking>('getExistingBookings', {
      from: fromDate,
      until: toDate,
    }).then((res) => {
      if (res.status === 'OK' && res.results !== null) {
        setExistingBookings(res.results)
      }
    })
  }, [currentDate, setExistingBookings])

  useEffect(() => {
    refreshExistingBookings()
  }, [currentDate, setExistingBookings])

  // Setup Filter Options Pickup/Dropoff date
  useEffect(() => {
    const fromDateObj = dayjs(filterOptions?.fromDate)
    const today = dayjs()
    let roundedMinute = Math.ceil(today.minute() / 15) * 15
    let roundedHour = today.hour()

    if (roundedMinute === 60) {
      roundedMinute = 0
      roundedHour += 1
    }

    if (!filterOptions.fromDate || !filterOptions.toDate || fromDateObj.isBefore(today, 'day')) {
      dispatch(setFilterOptions({
        fromDate: today.format('YYYY-MM-DD'),
        pickupHour: roundedHour,
        pickupMinute: roundedMinute,
        toDate: today.format('YYYY-MM-DD'),
        dropoffHour: roundedHour,
        dropoffMinute: roundedMinute,
      }))
    }
  }, [])

  // Filtered Vehicles by other filtering options (dependent on availableVehicles)
  const filteredVehicles = useMemo(() => availableVehicles
    .filter((vehicle) => {
      return vehicle.vehicle_rego_number.toLowerCase().includes(searchValue.toLowerCase()) ||
        vehicle.make_name.toLowerCase().includes(searchValue.toLowerCase()) ||
        vehicle.model_name.toLowerCase().includes(searchValue.toLowerCase()) ||
        vehicle.VEHICLE_ID.toLowerCase().includes(searchValue.toLowerCase())
    })
    .filter((vehicle) => {
      const vehicleBrand = vehicle.make_name.toLowerCase() + ' ' + vehicle.model_name.toLowerCase()

      if (filterOptions.make && vehicleBrand !== filterOptions.make.toLowerCase()) {
        return false
      }

      if (filterOptions.pickupLocation && vehicle.LOCATION_ID !== filterOptions.pickupLocation) {
        return false
      }

      if (filterOptions.range && vehicle.vehicle_range_km !== '0' && parseInt(vehicle.vehicle_range_km) < filterOptions.range) {
        return false
      }

      if (filterOptions.seats && vehicle.vehicle_nr_seats && vehicle.vehicle_nr_seats !== '0' && parseInt(vehicle.vehicle_nr_seats) < parseInt(filterOptions.seats)) {
        return false
      }

      if (filterOptions.luggages && vehicle.vehicle_nr_luggage && vehicle.vehicle_nr_luggage !== '0' && parseInt(vehicle.vehicle_nr_luggage) < parseInt(filterOptions.luggages)) {
        return false
      }

      if (filterOptions.fuelType && vehicle.vehicle_fuel.toLowerCase() !== filterOptions.fuelType.toLowerCase()) {
        return false
      }

      if (filterOptions.transmission && vehicle.vehicle_transmission.toLowerCase() !== filterOptions.transmission.toLowerCase()) {
        return false
      }

      if (filterOptions.attributes && filterOptions.attributes.length > 0 && !filterOptions.attributes.every(attr => vehicle.vehicle_attribute_names.toLowerCase().includes(attr.toLowerCase()))) {
        return false
      }

      if (filterOptions.features && filterOptions.features.length > 0 && !filterOptions.features.every(feature => vehicle.vehicle_feature_names.toLowerCase().includes(feature.toLowerCase()))) {
        return false
      }

      return true
    }), [availableVehicles, searchValue, filterOptions])

  const toggleFilter = () => {
    setFilterHide((isHidden) => !isHidden)
  }

  const clearFilter = () => {
    dispatch(resetFilterOptions())
  }

  // Toggle Calendar View on or off
  const updateCalendarView = (value: boolean) => {
    setCalendarView(value)

    if (value) {
      setFilterHide(true)
    }
  }

  return (
    <AnimatedPage>
      <StyledContainer maxWidth="lg">
        <Header
          availableVehicles={filteredVehicles.length}
          onToggleFilter={toggleFilter}
          onClearFilter={clearFilter}
          isCalendarView={isCalendarView}
          setCalendarView={updateCalendarView}
          numFilterChanges={countFilterOptionsChanges(filterOptions)}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        <StyledBox>
          <FilterSidebar
            hide={isFilterHide}
            availableVehicles={filteredVehicles.length}
            onClearFilter={clearFilter}
            onHideFilter={toggleFilter}
          />
          <StyledMainContainer sx={{ marginTop: pxToRem(24), width: '100%' }} $hide={isFilterHide}>
            {isCalendarView ? (
              <VehicleSearchContext.Provider value={{ vehicleSearchString: searchValue, setVehicleSearchString: setSearchValue }}>
                <CalendarViewFragment
                  existingBookings={existingBookings}
                  currentDate={currentDate}
                  allVehicles={allVehicles}
                  setActiveVehicleId={setActiveVehicleId}
                  setCurrentDate={setCurrentDate}
                  searchValue={searchValue}
                />
              </VehicleSearchContext.Provider>
            ) : (
              <Grid container justifyContent="flex-start" >
                {!initialLoad && filteredVehicles.map((vehicle, idx) => (
                  <StyledVehicleCardGrid item key={idx} xs={12} sm={isFilterHide ? 6 : 12} md={isFilterHide ? 4 : 6}>
                    <VehicleCardFragment
                      vehicleId={vehicle.VEHICLE_ID}
                      onClick={() => {
                        setActiveVehicleId(vehicle.VEHICLE_ID)
                      }}
                    />
                  </StyledVehicleCardGrid>
                ))}
                {!initialLoad && filteredVehicles.length === 0 && (
                  <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <StyledTypography variant="body1" align="center">
                      No vehicles found
                    </StyledTypography>
                    <StyledDescriptionTypography variant="body1" align="center">
                      No vehicles match your search criteria, or the current user does not have permissions to access any vehicles
                    </StyledDescriptionTypography>
                  </Grid>
                )}
                {initialLoad &&
                  [...Array(4)].map((_, idx) => (
                    <StyledVehicleCardGrid item key={idx} xs={12} sm={isFilterHide ? 6 : 12} md={isFilterHide ? 4 : 6}>
                      <VehicleCardPlaceholder />
                    </StyledVehicleCardGrid>
                  ))
                }
              </Grid>
            )}
          </StyledMainContainer>
        </StyledBox>

        {isMobile &&
          <MobileSlider
            wrapper
            portal="#slider"
            isOpen={Boolean(activeVehicleId)}
            onClose={() => {
              setActiveVehicleId(null)
            }}
            initialTitle="Add Booking"
          >
            <MobileSlider navigation level={0} idx={0}>
              <StyledSliderContent>
                <BookingForm
                  vehicleId={activeVehicleId || ''}
                  isMobileSlider={true}
                  isEditForm={false}
                  onFormSubmitSuccess={() => {
                    debouncedVehicleSearch()
                    refreshExistingBookings()
                    setActiveVehicleId(null)
                  }}
                />
              </StyledSliderContent>
            </MobileSlider>
          </MobileSlider>
        }
        {!isMobile && <Modal
          title={'Add Booking'}
          portal={'#modal'}
          open={Boolean(activeVehicleId)}
          onClose={() => {
            setActiveVehicleId(null)
          }}
        >
          <BookingForm
            vehicleId={activeVehicleId || ''}
            isEditForm={false}
            onFormSubmitSuccess={() => {
              debouncedVehicleSearch()
              refreshExistingBookings()
              setActiveVehicleId(null)
            }}
          />
        </Modal>}
      </StyledContainer>
    </AnimatedPage >
  )
}


const StyledContainer = styled(Container)`
  ${(props) => props.theme.breakpoints.up('sm')} {
    min-height: 1100px;
    overflow: hidden;
  }
`

const StyledBox = styled(Box)`
  position: relative;
  margin-bottom: ${(props) => props.theme.typography.pxToRem(72)};

  ${(props) => props.theme.breakpoints.up('sm')} {
    display: flex;
    overflow: visible;
    padding-left: ${(props) => props.theme.typography.pxToRem(24)};
    margin-left: ${(props) => props.theme.typography.pxToRem(-24)};
    margin-bottom: unset;
  }
`
const StyledMainContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== '$hide',
}) <{ $hide?: boolean }>`
  background-color: ${(props) => props.theme.palette.background.default};
  transition: margin-left ${(props) => props.theme.transitions.duration.standard}ms ease;

  ${(props) => props.theme.breakpoints.up('sm')} {
    margin-left: ${(props) => props.$hide ? '0' : 'calc(42% + 33px)'};
    width: ${(props) => props.$hide ? '100%' : 'calc(60% - 32px)'} !important;
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    margin-left: ${(props) => props.$hide ? '0' : 'calc(30% + 56px)'};
    width: ${(props) => props.$hide ? '100%' : 'calc(70% - 56px)'} !important;
  }
`

const StyledVehicleCardGrid = styled(Grid)`
  padding-bottom: ${(props) => props.theme.typography.pxToRem(24)};

  ${(props) => props.theme.breakpoints.up('sm')} {
    padding-right: ${(props) => props.theme.typography.pxToRem(24)};
  }
`

const StyledSliderContent = styled('div')`
  padding: 0 ${(props) => props.theme.typography.pxToRem(16)};
`

const StyledTypography = styled(Typography)`
  margin-top: ${(props) => props.theme.typography.pxToRem(48)};
  font-size: ${(props) => props.theme.typography.pxToRem(20)};
  color: ${(props) => props.theme.palette.text.disabled};

  ${(props) => props.theme.breakpoints.up('sm')} {
    font-weight: 300;
    font-size: ${(props) => props.theme.typography.pxToRem(24)};
  }
`

const StyledDescriptionTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.text.disabled};
  margin-top: ${(props) => props.theme.typography.pxToRem(4)};
  max-width: ${(props) => props.theme.typography.pxToRem(400)};

  ${(props) => props.theme.breakpoints.up('sm')} {
    font-weight: 300;
  }
`
